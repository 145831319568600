import React from 'react';
import { useParams } from 'react-router-dom';

import userApi, { IUser, IUserPermissionBody } from 'config/api/user/userApi';
import resourceEnum, { resourcePermission } from 'config/constants/resource';
import userRole from 'config/constants/userRole';
import toastHelper from 'config/helpers/toastHelper';

import { Button, Checkbox, Divider, Form } from 'antd';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import uniq from 'lodash/uniq';

const { useForm } = Form;

type FormValue = {
  'admin': boolean;
  'viewer': boolean;
  // editor: boolean;
  'financial-view': boolean;
  // 'financial-edit': boolean;
  [key: string]: boolean;
};

interface IProps {
  data: IUser;
  isGame: boolean;
  gameId?: string;
  handleCancel?: () => void;
  triggerRefresh?: () => void;
}
const { updateUserPermission } = userApi;

const UserSettingPermissionForm = ({ data, isGame, gameId, handleCancel, triggerRefresh }: IProps) => {
  const [form] = useForm<FormValue>();

  const { email } = useParams<{ email: string }>();

  const onFinish = (values: FormValue) => {
    const keys = Object.keys(values).filter((key) => values[key]);
    const permission = keys.map((key) => resourcePermission[key as keyof typeof resourcePermission]);
    let role = userRole.GUEST;
    if (values.admin) {
      role = userRole.ADMIN;
    } else if (values.viewer) {
      role = userRole.VIEWER;
    }
    const permissionBody: IUserPermissionBody = {
      permission: {
        [isGame && gameId ? gameId : 'account']: {
          role,
          resources: uniq(flatten(permission)).filter((item) => !!item),
        },
      },
    };

    if (email) {
      updateUserPermission(email, permissionBody)
        .then(() => {
          toastHelper.success('User updated');
          sessionStorage.removeItem('addUserEmail');
          if (triggerRefresh) triggerRefresh();
          if (handleCancel) handleCancel();
        })
        .catch(() => {
          toastHelper.error('Failed to update user');
        });
    }
  };

  return (
    <div className="rounded-2xl bg-white px-3 py-2 md:px-6 md:py-4">
      <h5 className="mb-2 text-16 font-semibold">Permission for {isGame ? gameId : 'account'}</h5>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={{
          admin:
            get(data, `permission.${isGame && gameId ? gameId : 'account'}.role`, userRole.GUEST) === userRole.ADMIN ||
            data.role === userRole.SUPERADMIN,
          viewer:
            get(data, `permission.${isGame && gameId ? gameId : 'account'}.role`, userRole.GUEST) !== userRole.GUEST,
          financial: get(
            data,
            `permission.${isGame && gameId ? gameId : 'account'}.resources`,
            [] as resourceEnum[],
          ).includes(resourceEnum.MONETIZATION),
        }}
      >
        <section className="ml-3">
          <h6 className="mb-4 text-16 font-medium">App access</h6>
          <div className="pl-3">
            <Form.Item valuePropName="checked" name="admin" className="mb-3">
              <Checkbox
                onChange={(e) => {
                  const checked = e.target.checked;
                  if (checked) {
                    form.setFieldsValue({
                      viewer: checked,
                      // 'editor': checked,
                      financial: checked,
                      // 'financial-edit': checked,
                    });
                  }
                }}
              >
                Admin (all permissions)
              </Checkbox>
            </Form.Item>
            <Form.Item valuePropName="checked" name="viewer" className="mb-3">
              <Checkbox>View App information</Checkbox>
            </Form.Item>
            <Form.Item valuePropName="checked" name="editor" className="mb-3">
              <Checkbox disabled>Edit App information</Checkbox>
            </Form.Item>
          </div>
        </section>
        <Divider className="my-3" />

        <section className="ml-3">
          <h6 className="mb-4 text-16 font-medium">Financial data</h6>
          <div className="pl-3">
            <Form.Item valuePropName="checked" name="financial" className="mb-3">
              <Checkbox>View Financial data</Checkbox>
            </Form.Item>
            <Form.Item valuePropName="checked" name="financial-edit" className="mb-3">
              <Checkbox disabled>Edit report</Checkbox>
            </Form.Item>
          </div>
        </section>

        <div className="flex justify-end gap-3">
          <Form.Item>
            <Button htmlType="submit" size="small" type="primary">
              Save
            </Button>
          </Form.Item>
          {isGame && gameId && (
            <Button size="small" onClick={handleCancel}>
              Cancel
            </Button>
          )}
        </div>
      </Form>
    </div>
  );
};

export default UserSettingPermissionForm;
