import uniqueId from 'lodash/uniqueId';

interface Props {
  headers: string[];
  data: string[][];
}

const FunnelTable = ({ headers, data }: Props) => {
  return (
    <div className="h-[64vh] w-fit overflow-auto">
      <table className="w-fit max-w-full">
        <thead className="sticky top-0">
          <tr key={uniqueId()}>
            {headers.map((label) => (
              <th key={uniqueId()} className={'table-head font-medium'}>
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => {
            const len = row.length;

            const opacity = parseFloat(row[len - 1]);

            return (
              <tr
                key={uniqueId()}
                style={{
                  backgroundColor: opacity > 0 ? `rgba(250,146,102, ${0.5 + opacity})` : 'inherit',
                  // backgroundColor: 'inherit',
                }}
              >
                {row.slice(0, len - 1).map((value, i) => (
                  <td key={uniqueId()} className={'table-border'}>
                    <div className="table-cohort-cell px-4 py-2">
                      <p className={`${i !== 1 ? '' : 'text-left'}`}>{value}</p>
                    </div>
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FunnelTable;
