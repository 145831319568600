import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { BsPersonFill } from 'react-icons/bs';
import { IoIosChatboxes } from 'react-icons/io';
import { IoFootsteps, IoGameController, IoHeart } from 'react-icons/io5';
import { MdAccessTimeFilled } from 'react-icons/md';
import { RiUserHeartLine, RiUserVoiceFill } from 'react-icons/ri';

import LayoutContextConsumer from 'contexts/LayoutContext';

import useDocumentTitle from 'hooks/useDocumentTitle';

import SplineChart from 'components/feature/charts/SplineChart';
import ChartContainer from 'components/shared/ChartContainer';
import CountrySelect from 'components/shared/CountrySelect';
import Skeleton from 'components/shared/Skeleton';
import Widget from 'components/shared/Widget';

import mimilandApi from 'config/api/bigquery/mimilandApi';
import sessionApi, { ISessionOverview } from 'config/api/bigquery/sessionApi';
import dateHelper from 'config/helpers/dateHelper';
import numberHelper from 'config/helpers/numberHelper';

import { Col, Row } from 'antd';
import clsx from 'clsx';
import get from 'lodash/get';

interface IDAU {
  period: string;
  value: number;
}

interface IStepSummary {
  period: string;
  userChat: {
    value: number;
    percent: number;
  };
  totalMoveDistance: {
    value: number;
    percent: number;
  };
  totalChatTimes: {
    value: number;
    percent: number;
  };
}

interface ILove {
  period: string;
  totalLove: {
    value: number;
    percent: number;
  };
  totalUsers: {
    value: number;
    percent: number;
  };
}

const STEP_DISTANCE = 0.5;

const OverviewPage = () => {
  useDocumentTitle('Overview');
  const [selectedCountry, setSelectedCountry] = useState<string>('All');
  const { currentApp, platform } = LayoutContextConsumer();
  const {
    data: overviewData,
    isLoading: isSessionLoading,
    isError: isSessionError,
  } = useQuery(
    ['overview', currentApp, platform, selectedCountry],
    async (): Promise<ISessionOverview> => {
      const response = await sessionApi.getOverview({
        params: {
          platform: platform && platform !== 'unified' ? platform : undefined,
          country: selectedCountry && selectedCountry !== 'All' ? selectedCountry : undefined,
        },
      });
      return response as unknown as ISessionOverview;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: DAUData,
    isLoading: isDAULoading,
    isError: isDAUError,
  } = useQuery(
    ['DAU', currentApp, platform, selectedCountry],
    async () => {
      const response = await sessionApi.getDAUHourly({
        params: {
          platform: platform && platform !== 'unified' ? platform : undefined,
          country: selectedCountry && selectedCountry !== 'All' ? selectedCountry : undefined,
        },
      });
      return response as unknown as IDAU[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: stepSummaryData,
    isLoading: isStepSummaryLoading,
    isError: isStepSummaryError,
  } = useQuery(
    ['step-summary-overview', currentApp, platform, selectedCountry],
    async () => {
      const response = await mimilandApi.getMimilandOverview({
        params: {
          platform: platform && platform !== 'unified' ? platform : undefined,
        },
      });
      return response as unknown as IStepSummary;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: loveData,
    isLoading: isLoveLoading,
    isError: isLoveError,
  } = useQuery(
    ['love-overview', currentApp, platform, selectedCountry],
    async () => {
      const response = await mimilandApi.getMimilandLoveOverview({
        params: {
          platform: platform && platform !== 'unified' ? platform : undefined,
        },
      });
      return response as unknown as ILove;
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const additionalPercent = useMemo(() => {
    if (!overviewData || !stepSummaryData || !loveData) return null;

    const DAU = get(overviewData, 'DAU.value', 1);
    const prevDAU = (100 * get(overviewData, 'DAU.value', 0)) / (100 + get(overviewData, 'DAU.percent', 0));

    const userChat = get(stepSummaryData, 'userChat.value', 0);
    const userChatRate = (userChat / DAU) * 100;
    const prevUserChat =
      (100 * get(stepSummaryData, 'userChat.value', 0)) / (100 + get(stepSummaryData, 'userChat.percent', 0));
    const prevUserChatRate = (prevUserChat / prevDAU) * 100;

    const totalChatTimes = get(stepSummaryData, 'totalChatTimes.value', 0);
    const avgTotalChatTimes = totalChatTimes / DAU;
    const prevTotalChatTimes =
      (100 * get(stepSummaryData, 'totalChatTimes.value', 0)) /
      (100 + get(stepSummaryData, 'totalChatTimes.percent', 0));
    const prevAvgTotalChatTimes = prevTotalChatTimes / prevDAU;

    const totalLove = get(loveData, 'totalLove.value', 0);
    const avgTotalLove = totalLove / DAU;
    const prevTotalLove = (100 * get(loveData, 'totalLove.value', 0)) / (100 + get(loveData, 'totalLove.percent', 0));
    const prevAvgTotalLove = prevTotalLove / prevDAU;

    const userLove = get(loveData, 'totalUsers.value', 0);
    const userLoveRate = (userLove / DAU) * 100;
    const prevUserLove = (100 * get(loveData, 'totalUsers.value', 0)) / (100 + get(loveData, 'totalUsers.percent', 0));
    const prevUserLoveRate = (prevUserLove / prevDAU) * 100;

    return {
      userChat: {
        value: userChatRate,
        percent: (userChatRate / prevUserChatRate) * 100 - 100,
      },
      totalChatTimes: {
        value: avgTotalChatTimes,
        percent: (avgTotalChatTimes / prevAvgTotalChatTimes) * 100 - 100,
      },

      totalLove: {
        value: avgTotalLove,
        percent: (avgTotalLove / prevAvgTotalLove) * 100 - 100,
      },

      userLove: {
        value: userLoveRate,
        percent: (userLoveRate / prevUserLoveRate) * 100 - 100,
      },
    };
  }, [stepSummaryData, loveData, overviewData]);

  return (
    <div>
      <section>
        <h3 className="page-section-title">Overview</h3>
        <div className="mt-2 flex md:mt-3 lg:mt-4">
          <CountrySelect selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
        </div>
        <Row
          className="mt-6 mb-6 md:mt-9 md:mb-11"
          gutter={[
            { xs: 12, sm: 12, md: 30 },
            { xs: 12, sm: 12, md: 30 },
          ]}
        >
          <Col span={24} sm={12} lg={6}>
            <Widget
              label="DAU"
              number={get(overviewData, 'DAU.value', 0)}
              percent={get(overviewData, 'DAU.percent', 0)}
              icon={<BsPersonFill size={30} />}
              isLoading={isSessionLoading || isSessionError}
              description={`The number of unique users from ${dateHelper.formatText(
                new Date(get(overviewData, 'period') as string),
              )}. Compared to the same weekday 7 days ago.`}
            />
          </Col>
          <Col span={24} sm={12} lg={6}>
            <Widget
              label="Sessions"
              number={get(overviewData, 'sessions.value', 0)}
              percent={get(overviewData, 'sessions.percent', 0)}
              icon={<IoGameController size={30} />}
              isLoading={isSessionLoading || isSessionError}
              description={`The count of sessions played ${dateHelper.formatText(
                new Date(get(overviewData, 'period') as string),
              )}. Compared to the same weekday 7 days ago.`}
            />
          </Col>
          <Col span={24} sm={12} lg={6}>
            <Widget
              label="Playtime"
              number={get(overviewData, 'playtime.value', 0)}
              percent={get(overviewData, 'playtime.percent', 0)}
              icon={<MdAccessTimeFilled size={30} />}
              isLoading={isSessionLoading || isSessionError}
              isTime={true}
              description={`The average playtime per user ${dateHelper.formatText(
                new Date(get(overviewData, 'period') as string),
              )}. Compared to the same weekday 7 days ago.`}
            />
          </Col>
          {/* <Col span={24} sm={12} lg={6}>
            <Widget
              label="New users"
              number={get(overviewData, 'newUsers.value', 0)}
              percent={get(overviewData, 'newUsers.percent', 0)}
              icon={<BsFillPersonPlusFill size={30} />}
              isLoading={isSessionLoading || isSessionError}
              description={`The count of new users ${dateHelper.formatText(
                new Date(get(overviewData, 'period') as string),
              )}. Compared to the same weekday 7 days ago.`}
            />
          </Col> */}

          <Col span={24} sm={12} lg={6}>
            <Widget
              label="Average steps"
              number={
                get(stepSummaryData, 'totalMoveDistance.value', 0) / get(overviewData, 'DAU.value', 1) / STEP_DISTANCE
              }
              percent={get(stepSummaryData, 'totalMoveDistance.percent', 0)}
              icon={<IoFootsteps size={30} />}
              isLoading={isStepSummaryLoading || isStepSummaryError}
              description={`The average steps ${dateHelper.formatText(
                new Date(get(stepSummaryData, 'period') as string),
              )}. Compared to the same weekday 7 days ago.`}
            />
          </Col>

          <Col span={24} sm={12} lg={6}>
            <Widget
              label="User chat"
              number={get(stepSummaryData, 'userChat.value', 0)}
              percent={get(stepSummaryData, 'userChat.percent', 0)}
              icon={<RiUserVoiceFill size={30} />}
              isLoading={isStepSummaryLoading || isStepSummaryError}
              description={`Users chat count ${dateHelper.formatText(
                new Date(get(stepSummaryData, 'period') as string),
              )}. Compared to the same weekday 7 days ago.`}
              additional={
                additionalPercent && (
                  <div className="text-grey text-xs text-right">
                    <p>Rate: {additionalPercent.userChat.value.toFixed(2) + '%'}</p>
                    <p className={clsx(additionalPercent.userChat.percent > 0 ? 'text-green' : 'text-primary')}>
                      {(additionalPercent.userChat.percent > 0 ? '+' : '') +
                        additionalPercent.userChat.percent.toFixed(2) +
                        '%'}
                    </p>
                  </div>
                )
              }
            />
          </Col>

          <Col span={24} sm={12} lg={6}>
            <Widget
              label="Chat times"
              number={get(stepSummaryData, 'totalChatTimes.value', 0)}
              percent={get(stepSummaryData, 'totalChatTimes.percent', 0)}
              icon={<IoIosChatboxes size={30} />}
              isLoading={isStepSummaryLoading || isStepSummaryError}
              description={`Chat times ${dateHelper.formatText(
                new Date(get(stepSummaryData, 'period') as string),
              )}. Compared to the same weekday 7 days ago.`}
              additional={
                additionalPercent && (
                  <div className="text-grey text-xs text-right">
                    <p>Avg: {additionalPercent.totalChatTimes.value.toFixed(2)}</p>
                    <p className={clsx(additionalPercent.totalChatTimes.percent > 0 ? 'text-green' : 'text-primary')}>
                      {(additionalPercent.totalChatTimes.percent > 0 ? '+' : '') +
                        additionalPercent.totalChatTimes.percent.toFixed(2) +
                        '%'}
                    </p>
                  </div>
                )
              }
            />
          </Col>

          <Col span={24} sm={12} lg={6}>
            <Widget
              label="User Love"
              number={get(loveData, 'totalUsers.value', 0)}
              percent={get(loveData, 'totalUsers.percent', 0)}
              icon={<RiUserHeartLine size={30} />}
              isLoading={isLoveLoading || isLoveError}
              description={`Users ${dateHelper.formatText(
                new Date(get(loveData, 'period') as string),
              )}. Compared to the same weekday 7 days ago.`}
              additional={
                additionalPercent && (
                  <div className="text-grey text-xs text-right">
                    <p>Rate: {additionalPercent.userLove.value.toFixed(2) + '%'}</p>
                    <p className={clsx(additionalPercent.userLove.percent > 0 ? 'text-green' : 'text-primary')}>
                      {(additionalPercent.userLove.percent > 0 ? '+' : '') +
                        additionalPercent.userLove.percent.toFixed(2) +
                        '%'}
                    </p>
                  </div>
                )
              }
            />
          </Col>

          <Col span={24} sm={12} lg={6}>
            <Widget
              label="Total Love"
              number={get(loveData, 'totalLove.value', 0)}
              percent={get(loveData, 'totalLove.percent', 0)}
              icon={<IoHeart size={30} />}
              isLoading={isLoveLoading || isLoveError}
              description={`Love ${dateHelper.formatText(
                new Date(get(loveData, 'period') as string),
              )}. Compared to the same weekday 7 days ago.`}
              additional={
                additionalPercent && (
                  <div className="text-grey text-xs text-right">
                    <p>Avg: {additionalPercent.totalLove.value.toFixed(2)}</p>
                    <p className={clsx(additionalPercent.totalLove.percent > 0 ? 'text-green' : 'text-primary')}>
                      {(additionalPercent.totalLove.percent > 0 ? '+' : '') +
                        additionalPercent.totalLove.percent.toFixed(2) +
                        '%'}
                    </p>
                  </div>
                )
              }
            />
          </Col>
        </Row>
      </section>
      <section>
        <h3 className="page-section-title">Active users</h3>
        <p className="page-section-subtitle">Last 24 hours</p>
        {DAUData && !isDAULoading && !isDAUError ? (
          <ChartContainer>
            <SplineChart
              data={DAUData?.map((item) => {
                return {
                  ...item,
                  period: item.period,
                };
              })}
              xField="period"
              yField="value"
              tooltip={{
                formatter: (datum) => {
                  return {
                    name: 'users',
                    value: datum.value,
                  };
                },
              }}
              xAxis={{
                label: {
                  formatter: (value) => {
                    return dateHelper.formatHours(new Date(value));
                  },
                },
                range: [0, 1],
              }}
            />
          </ChartContainer>
        ) : (
          <div className="mt-4 overflow-hidden rounded-[10px]">
            <Skeleton height={400} />
          </div>
        )}
      </section>
    </div>
  );
};

export default OverviewPage;
