import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';

import useDocumentTitle from 'hooks/useDocumentTitle';

import DashboardContent from 'components/feature/dashboard/DashboardContent';
import DashboardRetention from 'components/feature/dashboard/DashboardRetention';
import CountrySelect from 'components/shared/CountrySelect';
import CusSelect from 'components/shared/CusSelect';
import FullDatePicker from 'components/shared/FullDatePicker';
import Skeleton from 'components/shared/Skeleton';

import mimilandApi from 'config/api/bigquery/mimilandApi';
import sessionApi, { IDashboardSessionBody } from 'config/api/bigquery/sessionApi';
import versionHistoryApi, { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import dateHelper from 'config/helpers/dateHelper';

import { Col, Row } from 'antd';

const { getDashboardSession, getDashboardRetention } = sessionApi;
const { getAll } = versionHistoryApi;
const { getMimilandLoveData, getMimilandStepSummary, getMimilandFirstLaunch } = mimilandApi;

const { format, getDayBefore } = dateHelper;

export interface IDashboardSessionData {
  period: string;
  DAU: number;
  newUsers: number;
  playtimePerSession: number;
  playtimePerUser: number;
  sessions: number;
}

export interface IDashboardRetentionData {
  date: string;
  value: number;
  category: string | number;
  users: number;
}

export interface IMimilandLove {
  time: string;
  step: string;
  data: number;
}

export interface IMimilandTutorial {
  time: string;
  avgMess: number;
  avgDistance: number;
}

export interface IMimilandFirstLaunch {
  time: string;
  data: number;
  version: string;
}

const Dashboard = () => {
  useDocumentTitle('Dashboard');
  const { currentApp, platform } = LayoutContextConsumer();
  const [selectedCountry, setSelectedCountry] = useState<string>('All');
  const [selectedGroup, setSelectGroup] = useState<'date' | 'week'>('date');
  const [rangeDate, setRangeDate] = useState<IDashboardSessionBody>({
    start: format(getDayBefore(new Date(), 10)),
    end: format(getDayBefore(new Date(), 2)),
  });

  const {
    data: dashboardData,
    isLoading: isSessionLoading,
    isError: isSessionError,
  } = useQuery(
    ['dashboard', rangeDate, currentApp, platform, selectedCountry],
    async () => {
      const response = await getDashboardSession(
        {
          ...rangeDate,
          country: selectedCountry && selectedCountry !== 'All' ? selectedCountry : undefined,
        },
        {
          params: {
            platform: platform && platform !== 'unified' ? platform : undefined,
          },
        },
      );
      return response as unknown as IDashboardSessionData[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: retentionData,
    isLoading: isRetentionLoading,
    isError: isRetentionError,
  } = useQuery(
    ['retention', rangeDate, currentApp, platform, selectedCountry],
    async () => {
      const response = await getDashboardRetention(
        {
          ...rangeDate,
          country: selectedCountry && selectedCountry !== 'All' ? selectedCountry : undefined,
        },
        {
          params: {
            platform: platform && platform !== 'unified' ? platform : undefined,
          },
        },
      );

      return response as unknown as IDashboardRetentionData[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: versionHistoryData } = useQuery(
    ['version-history', currentApp, platform, rangeDate],
    async () => {
      const response = await getAll({
        params: {
          platform: platform && platform !== 'unified' ? platform : undefined,
          startDate: rangeDate.start,
          endDate: rangeDate.end,
        },
      });
      return response as unknown as IVersionHistory[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: mimilandLove } = useQuery(
    ['mimiland-love', currentApp, platform, rangeDate, selectedGroup],
    async () => {
      const response = await getMimilandLoveData(
        {
          start: rangeDate.start,
          end: rangeDate.end,
          groupBy: selectedGroup,
        },
        {
          params: {
            platform: platform && platform !== 'unified' ? platform : undefined,
          },
        },
      );

      return response as unknown as IMimilandLove[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: mimilandTutorial } = useQuery(
    ['mimiland-tutorial', currentApp, platform, rangeDate, selectedGroup],
    async () => {
      const response = await getMimilandStepSummary(
        {
          start: rangeDate.start,
          end: rangeDate.end,
          groupBy: selectedGroup,
        },
        {
          params: {
            platform: platform && platform !== 'unified' ? platform : undefined,
          },
        },
      );

      return response as unknown as IMimilandTutorial[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: mimilandFirstLaunch } = useQuery(
    ['mimiland-first-launch', currentApp, platform, rangeDate, selectedGroup],
    async () => {
      const response = await getMimilandFirstLaunch(
        {
          start: rangeDate.start,
          end: rangeDate.end,
        },
        {
          params: {
            platform: platform && platform !== 'unified' ? platform : undefined,
          },
        },
      );

      return response as unknown as IMimilandFirstLaunch[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return (
    <div>
      <section className="mb-12">
        <h3 className="page-section-title">Engagement</h3>
        <p className="page-section-subtitle">
          Metrics related to user engagement via retention, new/returning users and playtime
        </p>

        <div className="mb-4 flex flex-wrap gap-6 ">
          <FullDatePicker rangeDate={rangeDate} setRangeDate={setRangeDate} />
          <CountrySelect selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
          <div className="">
            <h6 className="mb-2 text-16 font-[500]">Group data by</h6>
            <CusSelect
              value={selectedGroup}
              options={[
                { value: 'date', label: 'Daily' },
                { value: 'week', label: 'Weekly' },
              ]}
              style={{
                minWidth: '200px',
              }}
              onChange={(selected) => {
                setSelectGroup(selected);
              }}
            />
          </div>
        </div>
        <div className="mb-5 flex justify-between">
          <h6 className="text-18 font-semibold">Retention</h6>
        </div>
        {isRetentionLoading || isRetentionError ? (
          <div className="w-full overflow-hidden rounded-[10px]">
            <Skeleton height={400} />
          </div>
        ) : (
          <>
            {retentionData && (
              <DashboardRetention data={retentionData} versionData={versionHistoryData ? versionHistoryData : []} />
            )}
          </>
        )}
      </section>

      <section>
        {isSessionLoading || isSessionError ? (
          <Row gutter={[28, 40]}>
            <Col span={24} lg={12}>
              <div className="w-full overflow-hidden rounded-[20px]">
                <Skeleton height={330} />
              </div>
            </Col>
            <Col span={24} lg={12}>
              <div className="w-full overflow-hidden rounded-[20px]">
                <Skeleton height={330} />
              </div>
            </Col>
            <Col span={24} lg={12}>
              <div className="w-full overflow-hidden rounded-[20px]">
                <Skeleton height={330} />
              </div>
            </Col>
            <Col span={24} lg={12}>
              <div className="w-full overflow-hidden rounded-[20px]">
                <Skeleton height={330} />
              </div>
            </Col>
          </Row>
        ) : (
          dashboardData && (
            <DashboardContent
              mimilandLove={mimilandLove ?? []}
              mimilandTutorial={mimilandTutorial ?? []}
              dashboardData={dashboardData}
              versionData={versionHistoryData ? versionHistoryData : []}
              mimilandFirstLaunch={mimilandFirstLaunch ?? []}
            />
          )
        )}
      </section>
    </div>
  );
};

export default Dashboard;
