const PRIMARY_COLOR = '#DD0331';
const SECONDARY_COLOR = '#5D91FF';

const RED_COLOR = '#DD0331';
const BLUE_COLOR = '#1D5FE6';
const GREEN_COLOR = '#39AA44';
const YELLOW_COLOR = '#FF9F11';
const PURPLE_COLOR = '#7F59C2';
const PINK_COLOR = '#E91F64';
const GREY_COLOR = '#8B8B8B';
const GREY_COLOR_2 = '#D8D8D8';
const BROWN_COLOR = '#A9907E';

const BG_GREY_COLOR = '#F1F3F6';
const BG_RED_COLOR = '#DD0331';

const TEXT_COLOR = '#000000';
const TEXT_COLOR_2 = '#4F4F4F';

const generateColor = () => {
  const colors = [];
  for (let i = 0; i < 100; i++) {
    colors.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`);
  }
  return colors;
};

export {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  RED_COLOR,
  BLUE_COLOR,
  GREEN_COLOR,
  YELLOW_COLOR,
  PURPLE_COLOR,
  PINK_COLOR,
  BROWN_COLOR,
  GREY_COLOR,
  GREY_COLOR_2,
  BG_GREY_COLOR,
  BG_RED_COLOR,
  TEXT_COLOR,
  TEXT_COLOR_2,
  generateColor,
};
