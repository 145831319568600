const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const totalValue = (data: number[]) => {
  return data.reduce((a: number, b: number) => a + b, 0);
};

const toPercent = (value: number, total: number) => {
  if (total === 0) {
    return '0.00%';
  }
  const result = (value / total) * 100;
  return result.toFixed(2) + '%';
};

const calPercent = (value: number, total: number) => {
  if (!value || !total) {
    return '0.00%';
  }

  const result = (value / total) * 100;
  return result.toFixed(2) + '%';
};

const calAvg = (data: number[]) => {
  if (data.length === 0) {
    return 0;
  }
  return data.reduce((a, b) => a + b, 0) / data.length;
};

const toMinute = (value: number) => {
  var minutes = Math.floor(value / 60000);
  var seconds = (value % 60000) / 1000;
  return minutes + 'm. ' + (seconds < 10 ? '0' : '') + seconds.toFixed(0) + 's.';
};

const addUnit = (value: number | string, unit: string) => {
  return value + unit;
};

const checkBoolean = (value: any) => {
  if (typeof value === 'number') {
    return true;
  }
  return !!value;
};
// func that check the string can parse into number
const isNumber = (value: string) => {
  return !isNaN(Number(value));
};

function sortVersions(versions: string[]): string[] {
  return versions.sort((a, b) => {
    const aParts = a.split('.').map(Number);
    const bParts = b.split('.').map(Number);

    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      const aValue = aParts[i] || 0;
      const bValue = bParts[i] || 0;

      if (aValue !== bValue) {
        return bValue - aValue;
      }
    }

    return 0;
  });
}

const numberHelper = {
  getRandomNumber,
  totalValue,
  toPercent,
  calPercent,
  toMinute,
  calAvg,
  addUnit,
  checkBoolean,
  isNumber,
  sortVersions,
};

export default numberHelper;
