import { useQuery } from '@tanstack/react-query';
import React from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';

import generalApi from 'config/api/bigquery/generalApi';
import { allCountries } from 'config/constants/general';

import TagSelect from 'components/shared/TagSelect';

import CusSelect from './CusSelect';

const { getCountries } = generalApi;

interface ICountrySelect {
  selectedCountry: string;
  setSelectedCountry: (country: string) => void;
}

const CountrySelect = ({ selectedCountry, setSelectedCountry }: ICountrySelect) => {
  const { currentApp } = LayoutContextConsumer();
  const { data: countries } = useQuery(['countries', currentApp], async () => {
    const data = await getCountries();
    return data as unknown as string[];
  });

  if (!countries) return null;
  return (
    <div className="">
      <h6 className="mb-2 text-16 font-[500]">Country</h6>
      <CusSelect
        value={selectedCountry}
        options={[allCountries, ...countries?.map((country: string) => ({ label: country, value: country }))]}
        style={{
          minWidth: '200px',
        }}
        onChange={(selected) => {
          setSelectedCountry(selected);
        }}
        tagRender={TagSelect}
        showSearch
      />
    </div>
  );
};

export default CountrySelect;
