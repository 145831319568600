import React from 'react';
import { BsPersonDash } from 'react-icons/bs';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import { RiAdminLine, RiEyeLine } from 'react-icons/ri';

export type USER_ROLE = 'guest' | 'viewer' | 'admin' | 'superadmin';

const userRole = {
  GUEST: 'guest' as USER_ROLE,
  ADMIN: 'admin' as USER_ROLE,
  VIEWER: 'viewer' as USER_ROLE,
  SUPERADMIN: 'superadmin' as USER_ROLE,
};

export const USER_LIMIT = 10;

export const userRoleIcon: {
  [key in USER_ROLE]: React.ElementType;
} = {
  guest: BsPersonDash,
  admin: RiAdminLine,
  viewer: RiEyeLine,
  superadmin: MdOutlineAdminPanelSettings,
};

export default userRole;
