import { useMemo } from 'react';

import { IDashboardRetentionData } from 'pages/dashboard/Dashboard';

import LineChart, { lineChartColors } from 'components/feature/charts/LineChart';
import ChartContainer from 'components/shared/ChartContainer';

import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import { CALENDAR_TEXT_ICON } from 'config/constants/general';
import { GREY_COLOR, PRIMARY_COLOR } from 'config/constants/theme';

import groupBy from 'lodash/groupBy';
import sum from 'lodash/sum';

interface IDashboardRetentionProps {
  data: IDashboardRetentionData[];
  versionData?: IVersionHistory[];
}

const DashboardRetention = ({ data, versionData }: IDashboardRetentionProps) => {
  const avgData = useMemo(() => {
    const grouped = groupBy(data, 'category');

    return Object.keys(grouped).map((key) => {
      /* Tạo ra các ngày có retention theo key vd: tìm các ngày có retention 7 */
      const listDate = grouped[key].map((item) => item.date);
      /* Lọc trong group của day0 ngày nào có trong list ngày thì tính tổng lại  */
      const total = sum(grouped[0].filter((item) => listDate.includes(item.date)).map((item) => item.users));
      /* tổng của retention theo key chia cho tổng của các ngày có retention key */
      return (sum(grouped[key].map((item) => item.users)) / total) * 100;
    });
  }, [data]);

  return (
    <ChartContainer>
      <LineChart
        data={data.filter((item) => item.category !== '0')}
        xField="date"
        yField="value"
        seriesField="category"
        tooltip={{
          formatter: (datum) => {
            return {
              name: datum.category,
              value: parseFloat(datum.value).toFixed(2) + '%',
            };
          },
          title(title, datum) {
            const verData = versionData?.find((item) => item.date === datum.date);
            return verData ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}` : title;
          },
        }}
        yAxis={{
          label: {
            formatter: (value) => {
              return value + '%';
            },
          },
        }}
        xAxis={{
          label: {
            style(_, index, items) {
              const date = items[index].id;
              const verData = versionData?.find((version) => version.date === date);

              return {
                fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                fontWeight: verData ? 600 : 400,
              };
            },
            formatter(text) {
              const verData = versionData?.find((version) => version.date === text);
              return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
            },
            autoRotate: true,
          },
          range: [0, 1],
        }}
        legend={{
          position: 'bottom',
          marker: (_, index) => {
            return {
              symbol: 'circle',
              style: {
                fill: lineChartColors[index],
              },
            };
          },
          itemValue: {
            formatter: (value) => {
              if (value) {
                return `(${avgData[parseInt(value)] ? avgData[parseInt(value)].toFixed(2) : 0}%)`;
              } else {
                return value;
              }
            },
          },
        }}
      />
    </ChartContainer>
  );
};

export default DashboardRetention;
