import { RouteProps } from 'react-router-dom';

import AddApp from 'pages/app/AddAppPage';
import ListApp from 'pages/app/ListAppPage';
import CohortPage from 'pages/cohorts/CohortPage';
import CustomTrackingPage from 'pages/custom-tracking/CustomTrackingPage';
import Dashboard from 'pages/dashboard/Dashboard';
import ExplorePage from 'pages/explore/ExplorePage';
import ExploreQueryBuilder from 'pages/explore/ExploreQueryBuilder';
import FunnelsCreate from 'pages/funnels/FunnelsCreatePage';
import FunnelsList from 'pages/funnels/FunnelsListPage';
import FunnelsPage from 'pages/funnels/FunnelsPage';
import ComingSoonPage from 'pages/main/ComingSoonPage';
import HomePage from 'pages/main/HomePage';
import MonetizationPage from 'pages/monetization/MonetizationPage';
import Overview from 'pages/overview/OverviewPage';
import RemoteConfigPage from 'pages/remote-config/RemoteConfigPage';
import SettingPage from 'pages/setting/SettingPage';
import UserSettingEditPage from 'pages/user-setting/UserSettingEditPage';
import UserSettingPage from 'pages/user-setting/UserSettingPage';
import VersionHistoryPage from 'pages/version-history/VersionHistoryPage';

const protectedRoutes: RouteProps[] = [
  {
    path: '/',
    element: <HomePage />,
  },
  {
    path: 'apps',
    element: <ListApp />,
  },
  {
    path: 'apps/add',
    element: <AddApp />,
  },
  {
    path: 'dashboard',
    element: <Dashboard />,
  },
  {
    path: 'overview',
    element: <Overview />,
  },
  {
    path: 'cohorts',
    element: <CohortPage />,
  },
  {
    path: 'funnels',
    element: <FunnelsPage />,
  },
  {
    path: 'funnels/create',
    element: <FunnelsCreate />,
  },
  {
    path: 'funnels/list',
    element: <FunnelsList />,
  },
  {
    path: 'explore',
    element: <ExplorePage />,
  },
  {
    path: 'explore/query-builder',
    element: <ExploreQueryBuilder />,
  },
  {
    path: 'remote-config',
    element: <RemoteConfigPage />,
  },
  {
    path: 'monetization',
    element: <ComingSoonPage />,
  },
  {
    path: 'version-history',
    element: <VersionHistoryPage />,
  },
  {
    path: 'setting',
    element: <SettingPage />,
  },
  {
    path: 'user-setting',
    element: <UserSettingPage />,
  },
  {
    path: 'user-setting/edit/:email',
    element: <UserSettingEditPage />,
  },
  {
    path: 'custom-tracking',
    element: <CustomTrackingPage />,
  },
];

export default protectedRoutes;
