import { memo } from 'react';

import { SECONDARY_COLOR } from 'config/constants/theme';

import { Area, AreaConfig } from '@ant-design/plots';

interface ISplineChartProps extends AreaConfig {
  data: any[];
  xField: string;
  yField: string;
}

const SplineChart = ({ data, xField, yField, ...props }: ISplineChartProps) => {
  const config: AreaConfig = {
    data,
    xField: xField,
    yField: yField,
    smooth: true,
    autoFit: true,
    xAxis: {
      range: [0, 1],
      label: {
        autoRotate: true,
      },
    },
    ...props,
    color: SECONDARY_COLOR,
  };

  return <Area {...config} />;
};

export default memo(SplineChart);
