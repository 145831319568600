import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import 'content/styles/sidebar-item.css';

import LayoutContextConsumer from 'contexts/LayoutContext';

import clsx from 'clsx';

export interface ISidebarItem {
  id: string;
  link: string;
  icon: React.ReactNode;
  label: string;
}

const SidebarItem = ({ id, link, icon, label }: ISidebarItem) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { closeSidebar, sidebarOpen } = LayoutContextConsumer();

  return (
    <>
      <div
        className={'sidebar-item flex cursor-pointer items-center gap-3 text-grey no-underline hover:text-primary'}
        onClick={() => {
          navigate(link);
          if (sidebarOpen) {
            closeSidebar();
          }
        }}
      >
        <div
          className={clsx(
            'flex h-9 w-9 items-center justify-center rounded-md bg-grey text-white hover:bg-primary ',
            pathname.includes(id) && 'bg-primary',
          )}
        >
          {icon}
        </div>
        <h4 className={clsx('text-15 font-[500] lg:text-[17px] ', pathname.includes(id) && 'font-[600] text-primary')}>
          {label}
        </h4>
      </div>
    </>
  );
};

export default SidebarItem;
