import React from 'react';

interface IChartContainer {
  children: React.ReactNode;
  isLoading?: boolean;
}

const ChartContainer = ({ children }: IChartContainer) => {
  return <div className="rounded-[20px] bg-white py-9 px-3">{children}</div>;
};

export default ChartContainer;
