import numberHelper from 'config/helpers/numberHelper';

import uniqueId from 'lodash/uniqueId';

interface Props {
  headers: string[];
  data: string[][];
  isCenterFull?: boolean;
}

const RawDataTable = ({ headers, data, isCenterFull }: Props) => {
  return (
    <div className="h-[64vh] w-fit overflow-auto">
      <table className="w-fit max-w-full">
        <thead className="sticky top-0">
          <tr key={uniqueId()}>
            {headers.map((label) => (
              <th key={uniqueId()} className={'table-head font-medium'}>
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={uniqueId()}>
              {row.map((value) => (
                <td key={uniqueId()} className={'table-border'}>
                  <div className="table-cohort-cell px-4 py-2">
                    <p className={`${numberHelper.isNumber(value) || isCenterFull ? '' : 'text-left'}`}>{value}</p>
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RawDataTable;
