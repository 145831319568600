import toastHelper from 'config/helpers/toastHelper';

import api from '..';

import { AxiosRequestConfig } from 'axios';

export interface IDashboardSessionBody {
  start?: string;
  end?: string;
  country?: string;
}

export interface IDashboardRetentionBody {
  start?: string;
  end?: string;
  country?: string;
}

export interface ISessionOverview {
  period: string;
  newUsers: {
    value: number;
    percent: number;
  };
  DAU: {
    value: number;
    percent: number;
  };
  sessions: {
    value: number;
    percent: number;
  };
  playtime: {
    value: number;
    percent: number;
  };
}

const sessionApi = {
  getOverview: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) return api.get(`session/${game}`, config);
    else toastHelper.error('Please select a game first');
  },

  getDAUHourly: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) return api.get(`dau-hourly/${game}`, config);
    else toastHelper.error('Please select a game first');
  },

  getDashboardSession: (body?: IDashboardSessionBody, config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) {
      if (body) return api.post(`session/dashboard/${game}`, body, config);
      return api.post(`session/dashboard/${game}`, null, config);
    } else toastHelper.error('Please select a game first');
  },

  getDashboardRetention: (body?: IDashboardRetentionBody, config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) {
      if (body) return api.post(`retention/dashboard/${game}`, body, config);
      return api.post(`retention/dashboard/${game}`, null, config);
    } else toastHelper.error('Please select a game first');
  },
};

export default sessionApi;
