import React, { useContext, useLayoutEffect, useState } from 'react';

import userApi from 'config/api/user/userApi';
import userRole, { USER_ROLE } from 'config/constants/userRole';
import { auth } from 'config/firebase';
import authApi from 'config/firebase/api/authApi';
import emailHelper from 'config/helpers/emailHelper';
import toastHelper from 'config/helpers/toastHelper';

import Loading from 'components/shared/Loading';

import { User } from 'firebase/auth';
import get from 'lodash/get';
import lowerCase from 'lodash/lowerCase';

interface IAuthValue {
  isAuth: boolean;
  loading: boolean;
  signIn(): Promise<User | any>;
  signOut(): Promise<void>;
  currentUser: User | null;
  role: USER_ROLE;
}

interface IAuthProvider {
  children: JSX.Element | JSX.Element[];
}

const { updateUserProfile, getUserDetail, logUser } = userApi;

const AuthContext = React.createContext<IAuthValue>({
  isAuth: false,
  loading: true,
  signIn: async () => {},
  signOut: async () => {},
  currentUser: null,
  role: userRole.VIEWER,
});

function useAuth() {
  const [isAuth, setIsAuth] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [role, setRole] = useState<USER_ROLE>(userRole.VIEWER);

  useLayoutEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setCurrentUser(user);
        console.log(user);
        const res = await getUserDetail();
        if (res) {
          const role = get(res, 'role', '') as string;
          if (role && role.toLowerCase() === userRole.SUPERADMIN) {
            setRole(userRole.SUPERADMIN);
          } else {
            setRole(lowerCase(get(res, 'permission.account.role', userRole.GUEST)) as USER_ROLE);
          }
        }
        setIsAuth(true);  
        setLoading(false);
        // Log the latest time user login
        logUser();
      } else {
        setIsAuth(false);
        setCurrentUser(null);
        setLoading(false);
      }
    });
    return unsubscribe;
  }, []);

  const values: IAuthValue = {
    isAuth,
    loading,
    role,
    async signIn() {
      try {
        const user = await authApi.signInByGoogle();
        if (user.email) {
          if (!emailHelper.checkTopeboxEmail(user.email)) {
            toastHelper.error('Chỉ email Topebox mới có quyền truy cập trang này');
            setIsAuth(false);
            authApi.signOut();
          } else {
            updateUserProfile({ displayName: user.displayName, photoURL: user.photoURL, id: user.uid })
              .then(() => {
                console.log('Profile updated');
              })
              .catch((err) => {
                console.log(err);
              });
            toastHelper.success('Sign in');
          }
        }

        return user;
      } catch (err) {
        toastHelper.error('Sign in failed');
        return err;
      }
    },
    async signOut() {
      try {
        authApi.signOut();
        setIsAuth(false);
        // Clear all storage
        localStorage.clear();
        sessionStorage.clear();
      } catch (err: any) {
        toastHelper.error(err.message);
      }
    },
    currentUser,
  };

  return values;
}

export function AuthProvider({ children }: IAuthProvider) {
  const auth: IAuthValue = useAuth();

  return (
    <AuthContext.Provider value={auth}>
      {!auth.loading ? (
        children
      ) : (
        <div className="flex h-[100vh] w-full items-center justify-center">
          <Loading />
        </div>
      )}
    </AuthContext.Provider>
  );
}

export default function AuthConsumer() {
  return useContext(AuthContext);
}
