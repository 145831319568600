import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';

import AvgEmote from 'components/feature/custom-tracking/AvgEmote';
import CatchCoCo from 'components/feature/custom-tracking/CatchCoCo';
import EmoteItemClick from 'components/feature/custom-tracking/EmoteItemClick';
import StepSummary from 'components/feature/custom-tracking/StepSummary';
import TabImpression from 'components/feature/custom-tracking/TabImpression';
import Teleport from 'components/feature/custom-tracking/Teleport';
import XMasEvent from 'components/feature/custom-tracking/XMasEvent';
import CusSelect from 'components/shared/CusSelect';
import FullDatePicker from 'components/shared/FullDatePicker';

import mimilandApi from 'config/api/bigquery/mimilandApi';
import dateHelper from 'config/helpers/dateHelper';
import numberHelper from 'config/helpers/numberHelper';

import { Radio } from 'antd';
import groupBy from 'lodash/groupBy';

const { format, getDayBefore } = dateHelper;

const { getMimilandVersions } = mimilandApi;

const listTabs = [
  'Emote Item Click',
  'Average Emote',
  'Emote Tab Impression',
  'Teleport',
  'Catch CoCo',
  'Chat/Distance',
  'XMas 2023',
];

const CustomTrackingPage = () => {
  const { platform } = LayoutContextConsumer();
  const [rangeDate, setRangeDate] = useState<{
    start?: string;
    end?: string;
  }>({
    start: format(getDayBefore(new Date(), 10)),
    end: format(getDayBefore(new Date(), 2)),
  });

  const [version, setVersion] = useState<string | null>(null);

  const [tab, setTab] = useState<string>(listTabs[0]);

  const { data: versions } = useQuery(['versions'], async () => {
    const res = await getMimilandVersions();

    return res as unknown as { version: string; platform: string }[];
  });

  const versionList = useMemo(() => {
    if (!versions) return [];
    const groupVersions = groupBy(versions, 'platform');

    let verList = [];
    if (platform !== 'unified') {
      verList = groupVersions[platform.toUpperCase()].map((item) => item.version);
    } else {
      verList = versions.map((item) => item.version);
      // remove duplicate
      verList = Array.from(new Set(verList));
    }
    const result = numberHelper.sortVersions(verList).map((item) => ({
      label: item,
      value: item,
    }));

    setVersion(result[0].value);
    return result;
  }, [platform, versions]);

  const renderTab = useMemo(() => {
    switch (tab) {
      case 'Emote Item Click':
        return <EmoteItemClick rangeDate={rangeDate} version={version} />;
      case 'Average Emote':
        return <AvgEmote rangeDate={rangeDate} version={version} />;

      case 'Emote Tab Impression':
        return <TabImpression rangeDate={rangeDate} version={version} />;

      case 'Teleport':
        return <Teleport rangeDate={rangeDate} version={version} />;

      case 'Catch CoCo':
        return <CatchCoCo rangeDate={rangeDate} version={version} />;

      case 'Chat/Distance':
        return <StepSummary rangeDate={rangeDate} version={version} />;

      case 'XMas 2023':
        return <XMasEvent rangeDate={rangeDate} version={version} />;

      default:
        return null;
    }
  }, [rangeDate, tab, version]);

  return (
    <div>
      <h3 className="page-section-title">Custom Tracking</h3>
      <Radio.Group
        value={tab}
        buttonStyle="solid"
        className="mt-4"
        onChange={(e) => {
          setTab(e.target.value);
        }}
      >
        {listTabs.map((item) => (
          <Radio.Button key={item} value={item}>
            {item}
          </Radio.Button>
        ))}
      </Radio.Group>

      <div className="my-4 flex flex-wrap gap-6">
        <FullDatePicker rangeDate={rangeDate} setRangeDate={setRangeDate} />
        {versions && (
          <div className="">
            <h6 className="mb-2 text-16 font-[500]">Group data by</h6>
            <CusSelect
              value={version}
              options={versionList}
              style={{
                minWidth: '200px',
              }}
              onChange={(e) => setVersion(e)}
            />
          </div>
        )}
      </div>

      {renderTab}
    </div>
  );
};

export default CustomTrackingPage;
