import React from 'react';
import { AiFillFunnelPlot, AiOutlineLineChart } from 'react-icons/ai';
import { FaWrench } from 'react-icons/fa';
import { MdOutlineAttachMoney } from 'react-icons/md';
import { RiCompass3Fill, RiFileChartFill, RiLayoutMasonryFill, RiSettings3Fill } from 'react-icons/ri';
import { TbVersionsFilled } from 'react-icons/tb';
import { TiHome } from 'react-icons/ti';

import Timezone from 'components/shared/Timezone';

import SidebarItem, { ISidebarItem } from './SidebarItem';

const listItems: ISidebarItem[] = [
  {
    id: 'overview',
    link: 'overview',
    icon: <TiHome size={18} />,
    label: 'Overview',
  },
  {
    id: 'dashboard',
    link: 'dashboard',
    icon: <RiLayoutMasonryFill size={18} />,
    label: 'Dashboard',
  },
  {
    id: 'cohorts',
    link: 'cohorts',
    icon: <RiFileChartFill size={18} />,
    label: 'Cohorts',
  },
  {
    id: 'funnels',
    link: 'funnels',
    icon: <AiFillFunnelPlot size={18} />,
    label: 'Funnels',
  },
  {
    id: 'explore',
    link: 'explore',
    icon: <RiCompass3Fill size={18} />,
    label: 'Explore',
  },
  {
    id: 'remote-config',
    link: 'remote-config',
    icon: <FaWrench size={18} />,
    label: 'Remote Configs',
  },
  {
    id: 'monetization',
    link: 'monetization',
    icon: <MdOutlineAttachMoney size={18} />,
    label: 'Monetization',
  },
  {
    id: 'version-history',
    link: 'version-history',
    icon: <TbVersionsFilled size={18} />,
    label: 'Version History',
  },
  {
    id: 'custom-tracking',
    link: 'custom-tracking',
    icon: <AiOutlineLineChart size={18} />,
    label: 'Custom Tracking',
  },
  {
    id: 'setting',
    link: 'setting',
    icon: <RiSettings3Fill size={18} />,
    label: 'Setting',
  },
];

const Sidebar = () => {
  return (
    <div className="flex h-full flex-col gap-8 p-2 min-[450px]:p-4 md:p-6 lg:p-8">
      {listItems.map((item) => {
        return <SidebarItem key={item.id} {...item} />;
      })}

      <div className="mx-auto mt-auto">
        <Timezone />
      </div>
    </div>
  );
};

export default Sidebar;
