import { useQuery } from '@tanstack/react-query';

import LayoutContextConsumer from 'contexts/LayoutContext';

import Loading from 'components/shared/Loading';

import mimilandApi from 'config/api/bigquery/mimilandApi';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import numberHelper from 'config/helpers/numberHelper';

import RawDataTable from '../charts/RawDataTable';

import { Col, Empty, Row } from 'antd';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
}

const { getMimilandXmasPass, getMimilandXMasSummary } = mimilandApi;
const { calPercent } = numberHelper;

interface IPass {
  time: string;
  platform: string;
  version: string;
  milestone: string;
  reach_users: number;
  claim_users: number;
}

interface ISummary {
  time: string;
  version: string;
  DAU: number;
  users_catch_elf: number;
  users_receive_gift: number;
  users_decor: number;
  users_heart: number;
}

const XMasEvent = ({ rangeDate, version }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data: summaryData, isLoading: summaryLoading } = useQuery(
    ['xmas-summary', rangeDate, version, platform],
    async () => {
      if (!rangeDate.start || !rangeDate.end || !version) return [];

      const res = await getMimilandXMasSummary({
        platform: platform === 'unified' ? undefined : platform,
        version,
        start: rangeDate.start,
        end: rangeDate.end,
      });

      return res as unknown as ISummary[];
    },
  );

  const { data: passData, isLoading: passLoading } = useQuery(['xmas-pass', rangeDate, version, platform], async () => {
    if (!rangeDate.start || !rangeDate.end || !version) return [];

    const res = await getMimilandXmasPass({
      platform: platform === 'unified' ? undefined : platform,
      version,
      start: rangeDate.start,
      end: rangeDate.end,
    });

    return res as unknown as IPass[];
  });

  console.log('summaryData', summaryData);

  const isLoading = passLoading || summaryLoading;

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="flex flex-col gap-10">
          {summaryData && summaryData.length > 0 ? (
            <div className="py-4">
              <h6 className="text-14 font-semibold lg:text-16">Summary</h6>
              <RawDataTable
                isCenterFull
                headers={[
                  'Date',
                  'DAU',
                  'Users Catch Elf',
                  'Catch Elf Rate',
                  'Users Receive Gift',
                  'Receive Gift Rate',
                  'Users Decor',
                  'Decor Rate',
                  'Users Heart',
                  'Heart Rate',
                ]}
                data={
                  summaryData?.map(
                    (item): [string, string, string, string, string, string, string, string, string, string] => [
                      item.time,
                      item.DAU.toFixed(0),
                      item.users_catch_elf.toFixed(0),
                      calPercent(item.users_catch_elf, item.DAU),
                      item.users_receive_gift.toFixed(0),
                      calPercent(item.users_receive_gift, item.DAU),
                      item.users_decor.toFixed(0),
                      calPercent(item.users_decor, item.DAU),
                      item.users_heart.toFixed(0),
                      calPercent(item.users_heart, item.DAU),
                    ],
                  ) || []
                }
              />
            </div>
          ) : (
            <Empty />
          )}

          {passData && passData.length > 0 ? (
            <div className="py-4">
              <h6 className="text-14 font-semibold lg:text-16">Pass</h6>
              <RawDataTable
                isCenterFull
                headers={['Platform', 'Version', 'Milestone', 'Reach Users', 'Claim Users']}
                data={
                  passData?.map((item): [string, string, string, string, string] => [
                    item.platform,
                    item.version,
                    item.milestone,
                    item.reach_users.toFixed(0),
                    item.claim_users.toFixed(0),
                  ]) || []
                }
              />
            </div>
          ) : (
            <Empty />
          )}
        </div>
      )}
    </div>
  );
};

export default XMasEvent;
