// import { useNavigate } from 'react-router-dom';
import LayoutContextConsumer from 'contexts/LayoutContext';

import useDocumentTitle from 'hooks/useDocumentTitle';

import ComingSoonPage from 'pages/main/ComingSoonPage';

import FunnelContent from 'components/feature/funnels/FunnelContent';

import { funnelOpt } from 'config/constants/funnel';

const FunnelsPage = () => {
  useDocumentTitle('Funnels');
  const { currentApp } = LayoutContextConsumer();

  if (!currentApp?.id || !Object.keys(funnelOpt).includes(currentApp.id)) {
    return <ComingSoonPage />;
  }

  return (
    <div className="mb-10 w-full ">
      <section className="mb-5">
        <h3 className="page-section-title">Funnels</h3>
        <p className="page-section-subtitle">
          Understand player progression and conversion by defining a desired player path via multiple steps.
        </p>
      </section>
      <FunnelContent />
    </div>
  );
};

export default FunnelsPage;
