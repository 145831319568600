import {
  IDashboardSessionData,
  IMimilandFirstLaunch,
  IMimilandLove,
  IMimilandTutorial,
} from 'pages/dashboard/Dashboard';

import LineChart from 'components/feature/charts/LineChart';
import ChartContainer from 'components/shared/ChartContainer';

import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import { CALENDAR_TEXT_ICON } from 'config/constants/general';
import { GREY_COLOR, PRIMARY_COLOR } from 'config/constants/theme';
import numberHelper from 'config/helpers/numberHelper';

import { Col, Row } from 'antd';

interface IDashboardContentProps {
  dashboardData: IDashboardSessionData[];
  versionData?: IVersionHistory[];
  mimilandLove: IMimilandLove[];
  mimilandTutorial: IMimilandTutorial[];
  mimilandFirstLaunch: IMimilandFirstLaunch[];
}

const { toMinute, calAvg } = numberHelper;

const DashboardContent = ({
  dashboardData,
  versionData,
  mimilandLove,
  mimilandTutorial,
  mimilandFirstLaunch,
}: IDashboardContentProps) => {
  return (
    <Row gutter={[28, 40]}>
      <Col span={24} lg={12}>
        <div className="mb-4 flex flex-wrap justify-between lg:flex-nowrap">
          <h6 className="text-14 font-semibold lg:text-16">Total Loves</h6>
        </div>
        <ChartContainer>
          <LineChart
            data={mimilandLove}
            xField="time"
            yField="data"
            seriesField="step"
            height={330}
            xAxis={{
              label: {
                style(_, index, items) {
                  const date = items[index].id;
                  const verData = versionData?.find((version) => version.date === date);

                  return {
                    fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                    fontWeight: verData ? 600 : 400,
                  };
                },
                formatter(text) {
                  const verData = versionData?.find((version) => version.date === text);
                  return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
                },
                autoRotate: true,
              },
              range: [0, 1],
            }}
            tooltip={{
              title(title, datum) {
                const verData = versionData?.find((item) => item.date === datum.time);

                return verData ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}` : title;
              },
            }}
          />
        </ChartContainer>
      </Col>
      <Col span={24} lg={12}>
        <div className="mb-4 flex flex-wrap justify-between lg:flex-nowrap">
          <h6 className="text-14 font-semibold lg:text-16">Average Message</h6>
        </div>
        <ChartContainer>
          <LineChart
            data={mimilandTutorial}
            xField="time"
            yField="avgMess"
            height={330}
            xAxis={{
              label: {
                style(_, index, items) {
                  const date = items[index].id;
                  const verData = versionData?.find((version) => version.date === date);

                  return {
                    fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                    fontWeight: verData ? 600 : 400,
                  };
                },
                formatter(text) {
                  const verData = versionData?.find((version) => version.date === text);
                  return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
                },
                autoRotate: true,
              },
              range: [0, 1],
            }}
            tooltip={{
              title(title, datum) {
                const verData = versionData?.find((item) => item.date === datum.time);

                return verData ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}` : title;
              },
            }}
          />
        </ChartContainer>
      </Col>
      <Col span={24} lg={12}>
        <div className="mb-4 flex flex-wrap justify-between lg:flex-nowrap">
          <h6 className="text-14 font-semibold lg:text-16">Average Distance</h6>
        </div>
        <ChartContainer>
          <LineChart
            data={mimilandTutorial}
            xField="time"
            yField="avgDistance"
            height={330}
            xAxis={{
              label: {
                style(_, index, items) {
                  const date = items[index].id;
                  const verData = versionData?.find((version) => version.date === date);

                  return {
                    fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                    fontWeight: verData ? 600 : 400,
                  };
                },
                formatter(text) {
                  const verData = versionData?.find((version) => version.date === text);
                  return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
                },
                autoRotate: true,
              },
              range: [0, 1],
            }}
            yAxis={{
              label: {
                formatter: (val) => `${val}m`,
              },
            }}
            tooltip={{
              formatter: (datum) => {
                return {
                  name: datum.time,
                  value: `${datum.avgDistance}m`,
                };
              },
              title(title, datum) {
                const verData = versionData?.find((item) => item.date === datum.time);

                return verData ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}` : title;
              },
            }}
          />
        </ChartContainer>
      </Col>
      <Col span={24} lg={12}>
        <div className="mb-4 flex flex-wrap justify-between lg:flex-nowrap">
          <h6 className="text-14 font-semibold lg:text-16">Average Duration Of First Launch</h6>
        </div>
        <ChartContainer>
          <LineChart
            data={mimilandFirstLaunch}
            xField="time"
            yField="data"
            seriesField="version"
            height={330}
            xAxis={{
              label: {
                style(_, index, items) {
                  const date = items[index].id;
                  const verData = versionData?.find((version) => version.date === date);

                  return {
                    fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                    fontWeight: verData ? 600 : 400,
                  };
                },
                formatter(text) {
                  const verData = versionData?.find((version) => version.date === text);
                  return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
                },
                autoRotate: true,
              },
              range: [0, 1],
            }}
            yAxis={{
              label: {
                formatter: (val) => `${val}s`,
              },
            }}
            tooltip={{
              formatter: (datum) => {
                return {
                  name: datum.time,
                  value: `${numberHelper.toMinute(datum.data * 1000)}`,
                };
              },
              title(title, datum) {
                const verData = versionData?.find((item) => item.date === datum.time);

                return verData ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}` : title;
              },
            }}
          />
        </ChartContainer>
      </Col>
      <Col span={24} lg={12}>
        <div className="mb-4 flex flex-wrap justify-between lg:flex-nowrap">
          <h6 className="text-14 font-semibold lg:text-16">Playtime (mean per user)</h6>
          <h6 className="text-14 font-normal lg:text-14">
            Avg. {toMinute(calAvg(dashboardData.map((item) => item.playtimePerUser)))}
          </h6>
        </div>
        <ChartContainer>
          <LineChart
            data={
              dashboardData
                ? dashboardData.map((data) => ({
                    period: data.period,
                    value: data.playtimePerUser / 60000,
                  }))
                : []
            }
            xField="period"
            yField="value"
            height={330}
            yAxis={{
              label: {
                formatter: (val) => `${val}m`,
              },
            }}
            xAxis={{
              label: {
                style(_, index, items) {
                  const date = items[index].id;
                  const verData = versionData?.find((version) => version.date === date);

                  return {
                    fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                    fontWeight: verData ? 600 : 400,
                  };
                },
                formatter(text) {
                  const verData = versionData?.find((version) => version.date === text);
                  return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
                },
                autoRotate: true,
              },
              range: [0, 1],
            }}
            tooltip={{
              formatter: (datum) => {
                return {
                  name: datum.period,
                  value: toMinute(datum.value * 60000),
                };
              },
              title(title, datum) {
                const verData = versionData?.find((item) => item.date === datum.period);

                return verData ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}` : title;
              },
            }}
          />
        </ChartContainer>
      </Col>
      <Col span={24} lg={12}>
        <div className="mb-4 flex flex-wrap justify-between lg:flex-nowrap">
          <h6 className="text-14 font-semibold lg:text-16">Playtime (mean per session)</h6>
          <h6 className="text-14 font-normal lg:text-14">
            Avg. {toMinute(calAvg(dashboardData.map((item) => item.playtimePerSession)))}
          </h6>
        </div>
        <ChartContainer>
          <LineChart
            data={
              dashboardData
                ? dashboardData.map((data) => ({
                    period: data.period,
                    value: data.playtimePerSession / 60000,
                  }))
                : []
            }
            xField="period"
            yField="value"
            height={330}
            yAxis={{
              label: {
                formatter: (val) => `${val}m`,
                autoRotate: true,
              },
              range: [0, 1],
            }}
            tooltip={{
              formatter: (datum) => {
                return {
                  name: datum.period,
                  value: numberHelper.toMinute(datum.value * 60000),
                };
              },
              title(title, datum) {
                const verData = versionData?.find((item) => item.date === datum.period);

                return verData ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}` : title;
              },
            }}
            xAxis={{
              label: {
                style(_, index, items) {
                  const date = items[index].id;
                  const verData = versionData?.find((version) => version.date === date);

                  return {
                    fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                    fontWeight: verData ? 600 : 400,
                  };
                },
                formatter(text) {
                  const verData = versionData?.find((version) => version.date === text);
                  return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
                },
                autoRotate: true,
              },
              range: [0, 1],
            }}
          />
        </ChartContainer>
      </Col>
      <Col span={24} lg={12}>
        <div className="mb-4 flex flex-wrap justify-between lg:flex-nowrap">
          <h6 className="text-14 font-semibold lg:text-16">
            Users (split by <span className="text-secondary">new</span>/<span className="text-yellow">returning</span>)
          </h6>
          <h6 className="text-14 font-normal lg:text-14">
            Avg. (
            <span className="text-secondary">{calAvg(dashboardData.map((item) => item.newUsers)).toFixed(0)}</span>/
            <span className="text-yellow">
              {calAvg(dashboardData.map((item) => item.DAU - item.newUsers)).toFixed(0)}
            </span>
            )
          </h6>
        </div>
        <ChartContainer>
          <LineChart
            data={
              dashboardData
                ? [
                    ...dashboardData.map((item) => {
                      return {
                        period: item.period,
                        value: item.newUsers,
                        category: 'New Users',
                      };
                    }),
                    ...dashboardData.map((item) => {
                      return {
                        period: item.period,
                        value: item.DAU - item.newUsers,
                        category: 'Returning Users',
                      };
                    }),
                  ]
                : []
            }
            xField="period"
            yField="value"
            seriesField="category"
            height={330}
            xAxis={{
              label: {
                style(_, index, items) {
                  const date = items[index].id;
                  const verData = versionData?.find((version) => version.date === date);

                  return {
                    fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                    fontWeight: verData ? 600 : 400,
                  };
                },
                formatter(text) {
                  const verData = versionData?.find((version) => version.date === text);
                  return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
                },
                autoRotate: true,
              },
              range: [0, 1],
            }}
            tooltip={{
              title(title, datum) {
                const verData = versionData?.find((item) => item.date === datum.period);

                return verData ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}` : title;
              },
            }}
          />
        </ChartContainer>
      </Col>
      <Col span={24} lg={12}>
        <div className="mb-4 flex flex-wrap justify-between lg:flex-nowrap">
          <h6 className="text-14 font-semibold lg:text-16">Session Count</h6>
          <h6 className="text-14 font-normal lg:text-14">
            Avg. {calAvg(dashboardData.map((item) => item.sessions)).toFixed(0)}
          </h6>
        </div>
        <ChartContainer>
          <LineChart
            data={
              dashboardData
                ? dashboardData.map((data) => ({
                    period: data.period,
                    value: data.sessions,
                  }))
                : []
            }
            xField="period"
            yField="value"
            height={330}
            xAxis={{
              label: {
                style(_, index, items) {
                  const date = items[index].id;
                  const verData = versionData?.find((version) => version.date === date);

                  return {
                    fill: verData ? PRIMARY_COLOR : GREY_COLOR,
                    fontWeight: verData ? 600 : 400,
                  };
                },
                formatter(text) {
                  const verData = versionData?.find((version) => version.date === text);
                  return `${verData ? CALENDAR_TEXT_ICON : ''}${text}`;
                },
                autoRotate: true,
              },
              range: [0, 1],
            }}
            tooltip={{
              title(title, datum) {
                const verData = versionData?.find((item) => item.date === datum.period);

                return verData ? `${verData.version} - ${verData.title} - ${verData.platform.toUpperCase()}` : title;
              },
            }}
          />
        </ChartContainer>
      </Col>
    </Row>
  );
};

export default DashboardContent;
