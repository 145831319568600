const emailHelper = {
  checkTopeboxEmail: (email: string) => {
    const organizationEmail = process.env.REACT_APP_ORGANIZATION_EMAIL;
    if (organizationEmail === 'dev') return true;

    if (email) {
      return email.split('@')[1] === organizationEmail;
    } else {
      return false;
    }
  },
};

export default emailHelper;
