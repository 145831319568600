import { Platform } from 'config/constants/platform';
import toastHelper from 'config/helpers/toastHelper';

import api from '..';

import { AxiosRequestConfig } from 'axios';

export interface IRetentionBody {
  period: number[];
  country: string | null;
  start?: string;
  end?: string;
  platform?: Platform;
  compaign?: string | null;
}

const cohortApi = {
  getCountries: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) return api.get(`countries/${game}`, config);
    else toastHelper.error('Please select a game first');
  },
  getRetention: (body?: IRetentionBody, config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) {
      if (body) return api.post(`retention/${game}`, body, config);
      return api.post(`retention/${game}`, null, config);
    } else toastHelper.error('Please select a game first');
  },
  getCompaigns: (config?: AxiosRequestConfig) => {
    const game = sessionStorage.getItem('currentApp');
    if (game) {
      return api.get(`retention/compaign/${game}`, config);
    } else {
      toastHelper.error('Please select a game first');
    }
  },
};

export default cohortApi;
