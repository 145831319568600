import React from 'react';

import {
  BLUE_COLOR,
  GREEN_COLOR,
  PINK_COLOR,
  PURPLE_COLOR,
  SECONDARY_COLOR,
  YELLOW_COLOR,
  generateColor,
} from 'config/constants/theme';

import { Line, LineConfig } from '@ant-design/plots';

export const lineChartColors = [
  SECONDARY_COLOR,
  YELLOW_COLOR,
  PURPLE_COLOR,
  PINK_COLOR,
  GREEN_COLOR,
  '#060047',
  BLUE_COLOR,
  ...generateColor(),
];

const LineChart = ({ data, xField, yField, seriesField, height, ...props }: LineConfig) => {
  const config: LineConfig = {
    data,
    xField,
    yField,
    seriesField,
    smooth: true,
    legend: {
      position: 'bottom',
      marker: (_, index) => {
        return {
          symbol: 'circle',
          style: {
            fill: lineChartColors[index],
          },
        };
      },
    },
    xAxis: {
      range: [0, 1],
      label: {
        autoRotate: true,
      },
    },

    lineStyle: {
      lineWidth: 3,
    },

    point: {
      size: 5,
    },

    color: lineChartColors,

    ...props,
  };

  return <Line {...config} />;
};

export default React.memo(LineChart);
