import { useQuery } from '@tanstack/react-query';

import LayoutContextConsumer from 'contexts/LayoutContext';

import Loading from 'components/shared/Loading';

import mimilandApi from 'config/api/bigquery/mimilandApi';
import { IVersionHistory } from 'config/api/version-history/versionHistoryApi';

import RawDataTable from '../charts/RawDataTable';

import { Empty } from 'antd';

interface Props {
  rangeDate: {
    start?: string;
    end?: string;
  };
  versionHistoryData?: IVersionHistory[];
  version: string | null;
}

interface ITeleport {
  time: string;
  version: string;
  scene_name: string;
  data: number;
}

const { getMimilandTeleport } = mimilandApi;

const Teleport = ({ rangeDate, version }: Props) => {
  const { platform } = LayoutContextConsumer();

  const { data, isLoading } = useQuery(['teleport', rangeDate, version, platform], () => {
    if (!rangeDate.start || !rangeDate.end || !version) return [];

    const res = getMimilandTeleport({
      platform: platform === 'unified' ? undefined : platform,
      version,
      start: rangeDate.start,
      end: rangeDate.end,
    });

    return res as unknown as ITeleport[];
  });

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : data && data.length > 0 ? (
        <RawDataTable
          isCenterFull
          headers={['Date', 'Version', 'Scene Name', 'Times']}
          data={
            data?.map((item): [string, string, string, string] => [
              item.time,
              item.version,
              item.scene_name,
              item.data.toFixed(0),
            ]) || []
          }
        />
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default Teleport;
