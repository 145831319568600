import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';

import ExploreChart from 'components/feature/explore/ExploreChart';
import Skeleton from 'components/shared/Skeleton';

import exploreApi from 'config/api/bigquery/exploreApi';
import sessionApi from 'config/api/bigquery/sessionApi';
import versionHistoryApi, { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import { filterCategory } from 'config/constants/explore';

import { useExploreStore } from 'stores/exploreStore';

const { getDashboardRetention } = sessionApi;

export interface IExploreFilterUserData {
  period: string;
  DAU: number;
  newUsers: number;
  returningUsers: number;
}

export interface IExploreRetentionData {
  date: string;
  value: number;
  category: string | number;
  users: number;
}

export interface IFilter {
  metric: string;
  category: string;
  country: string;
}

const { getAll: getVersions } = versionHistoryApi;

const ExploreEngagement = () => {
  const { filter, setCategories } = useExploreStore((state) => state);
  const { platform, currentApp } = LayoutContextConsumer();

  useEffect(() => {
    if (filter.metric === 'engagement') {
      setCategories(filterCategory);
    }
  }, [filter.metric, setCategories]);

  const { data, isLoading, isError } = useQuery(
    ['engagement', filter, platform, currentApp],
    async () => {
      if (filter.category) {
        if (filter.category === 'retention') {
          const response = await getDashboardRetention(
            {
              country: filter.country !== 'All' ? filter.country : undefined,
            },
            {
              params: {
                platform: platform && platform !== 'unified' ? platform : undefined,
              },
            },
          );
          return response as unknown as IExploreRetentionData[];
        } else {
          const response = await exploreApi.getUsers(
            {
              country: filter.country !== 'All' ? filter.country : undefined,
            },
            {
              params: {
                platform: platform && platform !== 'unified' ? platform : undefined,
              },
            },
          );
          return response as unknown as IExploreFilterUserData[];
        }
      }
      return [];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: versionHistoryData = [] } = useQuery(
    ['version-history', currentApp, platform],
    async () => {
      const response = await getVersions({
        params: {
          platform: platform && platform !== 'unified' ? platform : undefined,
        },
      });
      return response as unknown as IVersionHistory[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );
  return (
    <div>
      {isLoading || isError ? (
        <div className="w-full overflow-hidden rounded-[10px]">
          <Skeleton height={400} />
        </div>
      ) : (
        data && (
          <ExploreChart
            title={filterCategory.find((category) => category.value === filter.category)?.label}
            data={data}
            category={filter.category}
            versionData={versionHistoryData ? versionHistoryData : []}
          />
        )
      )}
    </div>
  );
};

export default ExploreEngagement;
