import { APP_DOMAIN } from 'config/constants/general';
import { auth } from 'config/firebase';

import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BIGQUERY_API,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    // Modify the request config here
    const token = await auth.currentUser?.getIdToken();
    // const domain = window.location.hostname;
    const domain = APP_DOMAIN;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers['Content-Type'] = 'application/json';
      config.headers['domain'] = domain;
    }
    return config;
  },
  (error: any) => {
    // Handle request error here
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response: AxiosResponse) => {
    // Modify the response data here
    if (response.data && response.data.errors) {
      return Promise.reject(response.data.errors);
    }
    return response && response.data;
  },
  (error: any) => {
    // Handle response error here
    return Promise.reject(error);
  },
);

export default api;
