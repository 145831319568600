import React from 'react';

import useWindowDimensions from 'hooks/useWindowDimensions';

import { SECONDARY_COLOR } from 'config/constants/theme';

import { Column, ColumnConfig } from '@ant-design/plots';

const FunnelChart = ({ data, xField, yField }: ColumnConfig) => {
  const dimension = useWindowDimensions();

  const config: ColumnConfig = {
    data,
    xField,
    yField,
    conversionTag: {
      offset: 150,
      size: 40,
      spacing: dimension && dimension?.width > 1590 ? 40 : 10,
      arrow: {
        style: {
          fill: '#ffffff',
          shadowColor: SECONDARY_COLOR,
          shadowBlur: 2,
        },
      },

      text: {
        style: {
          fill: SECONDARY_COLOR,
          fontFamily: 'Poppins',
          fontWeight: 600,
        },
      },
    },
    autoFit: true,
    xAxis: {
      label: {
        offset: 48,
        autoHide: true,
        autoRotate: false,
        style: {
          fontFamily: 'Poppins',
        },
      },
    },

    color: '#6C81F4',
    label: {
      position: 'bottom',
      offsetY: 54,
      style: {
        fill: '#000000',
        fontWeight: 700,
        textAlign: 'center',
        fontFamily: 'Poppins',
      },

      formatter: (datum) => {
        return `${datum.percent}\n${datum.users}`;
      },
    },

    columnStyle: {
      radius: [7, 7, 7, 7],
    },

    maxColumnWidth: 50,
    minColumnWidth: 30,
    tooltip: {
      formatter: (datum) => {
        return {
          name: datum.action,
          value: `${datum.users} users`,
        };
      },
    },
  };
  return <Column {...config} />;
};

export default FunnelChart;
