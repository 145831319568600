import api from '..';

import { AxiosRequestConfig } from 'axios';

const getCountries = (config?: AxiosRequestConfig) => {
  const game = sessionStorage.getItem('currentApp');
  if (game) return api.get(`countries/${game}`, config);
  return api.get('/countries');
};

const generalApi = {
  getCountries,
};

export default generalApi;
