import React from 'react';

import numberHelper from 'config/helpers/numberHelper';

import Skeleton from './Skeleton';

import { Popover } from 'antd';
import clsx from 'clsx';

export interface IWidget {
  label: string;
  number: number;
  percent: number;
  icon: React.ReactNode;
  isLoading?: boolean;
  isTime?: boolean;
  description?: string;
  additional?: React.ReactNode;
}

const Widget = ({
  label,
  number,
  percent,
  icon,
  isLoading = false,
  isTime = false,
  description,
  additional,
}: IWidget) => {
  if (isLoading) {
    return (
      <div className="overflow-hidden rounded-[10px]">
        <Skeleton height={130} />
      </div>
    );
  }

  return (
    <Popover
      title={label}
      content={<p className="max-w-[250px] break-words">{description}</p>}
      style={{
        maxWidth: '300px',
      }}
    >
      <div className="flex justify-between gap-2 rounded-[10px] bg-white px-4 py-5 xl:px-6">
        <div className="">
          <p className="text-[16px] font-[400] text-grey 2xl:text-[20px]">{label}</p>
          <h6 className="text-[20px] font-[600] text-[#141414] 2xl:text-[26px]">
            {isTime ? numberHelper.toMinute(number) : number.toFixed(2)}
          </h6>
          <span className={clsx(percent > 0 ? 'text-green' : 'text-primary', 'font-[500]')}>
            {percent > 0 ? '+' : ''}
            {percent.toFixed(2)}%
          </span>
        </div>
        <div className="flex flex-col justify-between items-end gap-2">
          <div className="flex h-[44px] w-[44px] items-center justify-center rounded-full bg-primary text-white ">
            {icon}
          </div>
          {additional}
        </div>
      </div>
    </Popover>
  );
};

export default Widget;
