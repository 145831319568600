import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import LayoutContextConsumer from 'contexts/LayoutContext';

import CohortChart from 'components/feature/cohorts/CohortChart';
import { ICohortData } from 'components/feature/cohorts/CohortChart';
import CusSelect from 'components/shared/CusSelect';
import FullDatePicker from 'components/shared/FullDatePicker';
import Loading from 'components/shared/Loading';
import TagSelect from 'components/shared/TagSelect';

import cohortApi from 'config/api/bigquery/cohortApi';
import versionHistoryApi, { IVersionHistory } from 'config/api/version-history/versionHistoryApi';
import dateHelper from 'config/helpers/dateHelper';

interface Period {
  label: string;
  value: number;
}

interface IFiltered {
  period: number[];
  country: string | null;
  range?: { start?: string; end?: string };
  compaign: string | null;
}

interface CohortData {
  [key: string]: number[];
}

const cohortSelect = [{ label: 'Returning(%)', value: 'returning' }];
const ALL = {
  label: 'All',
  value: 'All',
};

const daySelect = (): Period[] => {
  const data = [];

  for (let i = 1; i <= 90; i++) {
    data.push({ label: `Day ${i}`, value: i });
  }

  return data;
};

const defaultPeriod = [
  ...daySelect()
    .map((item) => item.value)
    .slice(0, 7),
  15,
  30,
];

const CohortContent = () => {
  const { currentApp, platform } = LayoutContextConsumer();
  const [selectedPeriod, setSelectedPeriod] = useState<number[]>(defaultPeriod);

  const [selectedCountry, setSelectedCountry] = useState<string>('All');

  const [selectedCompaign, setSelectedCompaign] = useState<string>('All');

  const [filtered, setFiltered] = useState<IFiltered>({
    period: [
      ...daySelect()
        .map((item) => item.value)
        .slice(0, 7),
      15,
      30,
    ],
    country: selectedCountry,
    compaign: selectedCompaign,
  });

  const [rangeDate, setRangeDate] = useState<{ start?: string; end?: string }>();

  const { data, isFetching } = useQuery(
    ['retention', filtered, currentApp, platform],
    async () => {
      const response = await cohortApi.getRetention({
        period: [0, ...filtered.period],
        country: filtered.country === 'All' ? null : filtered.country,
        start: filtered.range?.start,
        end: filtered.range?.end,
        platform: platform && platform !== 'unified' ? platform : undefined,
        compaign: filtered.compaign === 'All' ? null : filtered.compaign,
      });

      let data = {
        ...(response as unknown as CohortData),
      };
      // Cal total data
      const total = [0, ...filtered.period.map(() => 0)];

      Object.keys(data).forEach((key) => {
        for (let i = 0; i < data[key].length; i++) {
          total[i] += data[key][i];
        }
      });

      data = {
        Total: total,
        ...data,
      };
      return data;
    },
    { refetchOnWindowFocus: false },
  );

  const { data: versionHistoryData } = useQuery(
    ['version-history', currentApp, platform, filtered.range],
    async () => {
      const response = await versionHistoryApi.getAll({
        params: {
          platform: platform && platform !== 'unified' ? platform : undefined,
          startDate: filtered.range?.start,
          endDate: filtered.range?.end,
        },
      });
      return response as unknown as IVersionHistory[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: countries } = useQuery(
    ['country', currentApp],
    async () => {
      const response = await cohortApi.getCountries();
      return response as unknown as string[];
    },
    { refetchOnWindowFocus: false },
  );

  const { data: compaigns } = useQuery(
    ['retention', 'compaigns', currentApp],
    async () => {
      const response = await cohortApi.getCompaigns();
      return response as unknown as string[];
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (rangeDate?.start && rangeDate?.end) {
      const dateDiff = Math.abs(dateHelper.getDayDifferent(new Date(rangeDate.start), new Date(rangeDate.end)));

      // If the date range is greater than 7 days, we will show the period picker for all day in the range
      if (dateDiff > 7) {
        let newPeriod = daySelect()
          .map((item) => item.value)
          .slice(0, dateDiff);
        // The day15 and day30 is default so need check before adding
        if (dateDiff > 15 && dateDiff < 30) {
          newPeriod = [...newPeriod, 30];
        } else if (dateDiff <= 15) {
          newPeriod = [...newPeriod, 15, 30];
        }

        setSelectedPeriod([...newPeriod]);
      } else {
        // if the date range is less than 7 days, we will show the period picker for 7 days and day15 and day30
        setSelectedPeriod(defaultPeriod);
      }
    } else {
      setSelectedPeriod(defaultPeriod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeDate]);

  return (
    <div className="w-full">
      <section>
        <h3 className="page-section-title">Cohorts</h3>

        <p className="page-section-subtitle">
          Create cohorts of new users to see how different metrics affect them over time
        </p>
        <div className="flex flex-wrap items-end gap-6">
          <FullDatePicker rangeDate={rangeDate} setRangeDate={setRangeDate} />
          <div className="">
            <h6 className="mb-2 text-16 font-[500]">Cohorts picker</h6>
            <CusSelect
              defaultValue={'returning'}
              options={cohortSelect}
              style={{
                minWidth: '200px',
              }}
            />
          </div>

          <div className="max-w-lg flex-1">
            <h6 className="mb-2 text-16 font-[500]">Period</h6>
            <CusSelect
              value={selectedPeriod}
              options={daySelect()}
              style={{
                minWidth: '200px',
                width: '100%',
              }}
              onChange={(selected) => {
                setSelectedPeriod(selected.sort((a: number, b: number) => a - b));
              }}
              mode="multiple"
              tagRender={TagSelect}
              maxTagCount={'responsive'}
            />
          </div>

          {countries && (
            <div className="">
              <h6 className="mb-2 text-16 font-[500]">Country</h6>
              <CusSelect
                value={selectedCountry}
                options={[ALL, ...countries?.map((country: string) => ({ label: country, value: country }))]}
                style={{
                  minWidth: '200px',
                }}
                onChange={(selected) => {
                  setSelectedCountry(selected);
                }}
                tagRender={TagSelect}
                showSearch
              />
            </div>
          )}

          {compaigns && (
            <div className="">
              <h6 className="mb-2 text-16 font-[500]">Compaigns</h6>
              <CusSelect
                value={selectedCompaign}
                options={[ALL, ...compaigns?.map((compaign: string) => ({ label: compaign, value: compaign }))]}
                style={{
                  minWidth: '200px',
                }}
                onChange={(selected) => {
                  setSelectedCompaign(selected);
                }}
                tagRender={TagSelect}
                showSearch
              />
            </div>
          )}

          <button
            onClick={() => {
              setFiltered({
                ...filtered,
                period: [...selectedPeriod],
                country: selectedCountry,
                range: {
                  start: rangeDate?.start,
                  end: rangeDate?.end,
                },
                compaign: selectedCompaign,
              });
            }}
            className="cursor-pointer rounded-md border-none bg-primary px-7 py-1 font-sans leading-6 text-white outline-none hover:shadow-lg"
          >
            Apply
          </button>
        </div>
      </section>

      <section className="mt-12">
        <h3 className="text-[20px] font-[600] leading-[1]">Returning user (%) cohorts</h3>
        <p className="mt-4 mb-10 text-[14px] font-[400]">Cohorts of retention of all users for {currentApp?.name}</p>
        <div className="cohort-table max-h-[84vh] w-full overflow-auto">
          {isFetching && !data ? (
            <div className="flex min-h-[30vh] w-full items-center justify-center">
              <Loading />
            </div>
          ) : (
            <CohortChart
              data={data as ICohortData}
              labels={['Install', ...filtered.period.map((item) => daySelect()[item - 1].label)]}
              versionData={versionHistoryData ? versionHistoryData : []}
            />
          )}
        </div>
      </section>
    </div>
  );
};

export default CohortContent;
