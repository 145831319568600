import React from 'react';

import useDocumentTitle from 'hooks/useDocumentTitle';

import withPermission from 'hocs/withPermission';

import ComingSoonPage from 'pages/main/ComingSoonPage';

const RemoteConfigPage = () => {
  useDocumentTitle('Remote Config');
  return <ComingSoonPage />;
};

export default withPermission(RemoteConfigPage);
